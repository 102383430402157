import { useState } from 'react';
import "./InputField.css"

function InputField(params) {

    const [viewport, setViewport] = useState(document.documentElement.clientWidth);
    window.addEventListener('resize', () => {
        // viewport = document.documentElement.clientWidth;
        setViewport(document.documentElement.clientWidth);
    });

    const handleChange = (e) => {
        const { name, value } = e.target;  // Get name and value from the event target
        params.setFormData({
            ...params.formData,  // Copy existing form data
            [name]: value // Update the specific field that changed
        });

    };

    function validityMessageShow(e) {

        let validityMessage = (e?.target.nextElementSibling).nextElementSibling;


        if (!e.target.value) {

            validityMessage.innerHTML = "Please fill out this field";
        }


        else if (e.target.id === "email") {
            validityMessage.innerHTML = "Please enter a valid email address";
        }
        validityMessage.style.display = "block";

    }
    function handleFocus(e) {
        if (params.addPlusSymbol) {
            params.addPlusSymbol(e);
        }
    }

    // const nameRegex = /^[A-Za-z\s]+$/;
    const phoneRegex = /^[\d\+\-\(\)\.\s]*$/;

    function handleInput(e) {
        let validityMessage = (e?.target.nextElementSibling).nextElementSibling;
        if (e.target.id === "phone") {
            if (e.target.value.trim() == "" || e.target.value.isEmpty) {
                e.target.value = '+';
            }
        }
        validityMessageHide(e);

        // if (e.target.id === "name" && !nameRegex.test(e.target.value)) {
        //     // If it doesn't match, remove the last character
        //     e.target.value = e.target.value.slice(0, -1);

        //     validityMessage.innerHTML = "Invalid symbol. Please try again";
        //     validityMessage.style.display = "block"


        // }

        if (e.target.id === "phone" && !phoneRegex.test(e.target.value)) {
            // If it doesn't match, remove the last character
            e.target.value = e.target.value.slice(0, -1);

            validityMessage.innerHTML = "Invalid symbol. Please try again";
            validityMessage.style.display = "block"
        }

    }

    function validityMessageHide(e) {

        let validityMessage = (e?.target.nextElementSibling).nextElementSibling;
        validityMessage.innerHTML = "";
        validityMessage.style.display = "none";

    }


    function inputBlur(e) {
        if (e.target.value.trim() === "") {
            e.target.value = ""
        }
        params.setInput(e.target.value);
        if (params.id === "phone") {
            params.removePlusSymbol(e);
        }
    }

    function enterKeySet(e) {
        if (e.key === "Enter") {
            e.preventDefault();
        }
    }

    let groupClasses = `group ${params.class} ${viewport >= 992 ? "desktop" : "mobile"}`
    let inputClasses = `inputField  ${params.inputText !== "" ? "input-present" : ""} ${viewport >= 992 ? "desktop" : "mobile"} `
    let validityClasses = `validity-message  ${viewport >= 992 ? "desktop" : "mobile"}`;
    let placeholderClasses = `placeholder ${params.inputText !== "" ? "input-present" : ""} ${viewport >= 992 ? "desktop" : "mobile"} }`


    return <div className={groupClasses}>
        <input
            name={params.name}
            id={params.id}
            type={params.type}
            className={inputClasses}
            onKeyDown={enterKeySet}
            onInput={handleInput}
            onFocus={handleFocus}
            onBlur={inputBlur}
            onInvalid={validityMessageShow}
            onChange={handleChange}
            required></input>
        <label className={placeholderClasses}>{params.placeholder}</label>
        <span className={validityClasses}></span>
    </div>
}

export default InputField;