import { BrowserRouter, Routes, Route, useLocation, Navigate } from 'react-router-dom';
import Wrapper from './Components/Wrapper/Wrapper';
import { useLayoutEffect } from 'react';
import NavBar from './Components/NavBar/NavBar';
import Banner from './Components/Banner/Banner';
import ExploreTheProcess from './Components/ExploreTheProcess/ExploreTheProcess';
import Features from './Components/Features/Features';
import GetInTouch from './Components/GetInTouch copy/GetInTouch';
import Dataflow from './Components/Dataflow/Dataflow';
import PrivacyPolicy from './Pages/PrivacyPolicy/PrivacyPolicy'
import TermOfUse from './Pages/TermsOfUse/TermsOfUse';
import PageNotFound from './Pages/PageNotFound/PageNotFound';
import Footer from './Components/Footer/Footer';


function App() {
 
  return (
    <>
      <BrowserRouter>
        <NavBar />
        <Wrapper>
          <Routes>
            <Route path="/" element={<MainSite />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/terms-of-use" element={<TermOfUse />} />
            <Route path="/page-not-found" element={<PageNotFound />} />
            <Route path="*" element={<Navigate to="/page-not-found" />} /> 
          </Routes>
        </Wrapper>
        <Footer />
      </BrowserRouter>
    </>
  );
}

const MainSite = () => (
  <>
    <Banner />
    <ExploreTheProcess />
    <Features />
    <Dataflow />
    <GetInTouch />
  </>)


export default App;
