import "./PageNotFound.css";
import { useState } from "react";

function PageNotFound() {
    return <>
        <div id="page-not-found-container">
            <img id="page-not-found-container-image" src="images/404.png"></img>
            <span id="page-not-found-container-header">Oops...Target Missed!</span>
            <span id="page-not-found-container-content"> Return to the home page and aim for the right spot.</span>
            <a href = "/" id="page-not-found-container-button">Go to Home Page</a>
        </div>
    </>

}

export default PageNotFound;