import React, { useState, useEffect } from 'react';
import "./NavBar.css";
import { NavHashLink as HashLink } from 'react-router-hash-link';
import { useLocation } from 'react-router-dom';

function NavBar() {

  const location = useLocation();

  useEffect(() => {
    const sections = document.querySelectorAll(".section");

    const links = document.querySelectorAll(".nav-link");

    const thresholds = [0.8, 0.6, 0.4, 0.6, 0.8]; // Example thresholds for different sections
    const observers = []; // Array to store observers
    sections.forEach((section, index) => {
      const observer = new IntersectionObserver((entries) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            const targetId = entry.target.id;
            links.forEach(link => {
              const isActive = link.getAttribute("href").substring(1) === `#${targetId}`;
              link.classList.toggle("active", isActive);
              if (isActive) {
                link.focus();
              } else {
                link.blur();
              }
            });
          }
        });
      }, { threshold: thresholds[index % thresholds.length] }); // Use thresholds based on the section index

      observer.observe(section);
      observers.push(observer); // Store observer in array
    });

    return () => {
      // Clean up the observers on component unmount
      observers.forEach((observer, index) => observer.unobserve(sections[index]));
    };
  }, [location]);



  const [viewport, setViewport] = useState(document.documentElement.clientWidth);
  window.addEventListener('resize', () => {
    setViewport(document.documentElement.clientWidth);
  });

  let navBarClasses = `navbar navbar-expand-lg ${viewport >= 992 ? "desktop sticky-top" : "mobile"}`;
  let linkClasses = `navbar-brand .navbar.mobile ${viewport >= 992 ? "desktop" : "mobile"}`;
  let logoClasses = `logo ${viewport >= 992 ? "desktop" : "mobile"}`;

  return <nav className={navBarClasses}>
    <a className={linkClasses} href="/">
      <img className={logoClasses} src="./images/navbar/Logo.svg" alt="Your Logo" />
    </a>
    <div className="container-fluid">
      <div className="col-lg-2"></div>
      <div className="collapse navbar-collapse col-lg-10" id="navbarNav">
        <ul className="navbar-nav">
          <li style={{ display: "none" }}>
            <a href="#banner" className="nav-link"> </a>  {/* used in useEffect function */}
          </li>
          <li className="nav-item"><HashLink className={`nav-link rounded`} smooth to="/#explore">Explore the Process</HashLink></li>
          {/* <li className="nav-item">
                        <a className="nav-link rounded" href="/#explore">
                            Explore the Process
                        </a>
                    </li> */}
          <li className="nav-item"><HashLink className={`nav-link rounded`} smooth to="/#features">Features</HashLink></li>

          {/* <li className="nav-item">
                        <a className="nav-link rounded" href="/#features">
                            Features
                        </a>
                    </li> */}
          <li className="nav-item"><HashLink className={`nav-link rounded`} smooth to="/#dataflow">Dataflow</HashLink></li>
          {/* <li className="nav-item">
                        <a className="nav-link rounded" href="/#dataflow">
                            Dataflow
                        </a>
                    </li> */}
          <li className="nav-item"><HashLink className={`nav-link rounded`} smooth to="/#getInTouch">Get in Touch</HashLink></li>

          {/* <li className="nav-item">
                        <a className="nav-link rounded" href="/#getInTouch">
                            Get in Touch
                        </a>
                    </li> */}
        </ul>
      </div>
    </div>
  </nav>

}


export default NavBar;
